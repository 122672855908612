%fixed-small-button {
    min-width: 130px;
    max-width: 155px;
    width: 48%;

    .ABB_CommonUX_Button__icon {
        height: 24px;

        .ABB_CommonUX_Spinner__root {
            &.ABB_CommonUX_Spinner__small {
                height: 24px;
            }
        }
    }

    .ABB_CommonUX_Button__text {
        height: 24px;
        line-height: 24px;
    }
}