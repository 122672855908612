.notification {
    border-bottom: 1px solid lightgray;
    min-height: 85px;
    padding: 10px;

    .notification-header {
        height: 20px;

        & > h3, span{
            font-size: 13px !important;
        }        

        .notification-icon {
            width: 20px;
        }
    }

    .notification-message {
        padding-left: 19px;
    }
}
