
.loading-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(80, 77, 77, 0.514);    
    z-index: 9999;

    .loading-container{
        background: white;
        min-width: 340px;
        max-width: 800px;        
        min-height: 100px;
        padding: 1em 3em;
    }
}