.documents-card {
    min-height: 600px;

    .card-content {
        .documents-tab-control {
            border-top: 1px solid #bababa;
            // height: 100%;
            flex-grow: 1;

            & > ul {
                & > li {
                    border-top: 1px solid transparent;
                }
            }

            .document-box {
                margin: 5px;
                padding: 1em;
                border: 1px solid #e1e1e1;                

                p {
                    margin-bottom: 0;
                }

                .document-box-info {
                    flex: 1 1 70%;
                    .doc-title
                    {
                        font-weight: bold;
                    }
                }

                .document-box-date {
                    min-width: 120px;
                    min-height: 40px;
                    flex: 1 1 15%;
                }

                .document-box-buttons {
                    min-width: 120px; 
                    min-height: 40px;    
                    flex: 1 1 15%;   
                    
                    a{
                        color: unset;
                    }

                    .small-navi-item{
                        // width: 40px;

                        i{
                            font-size: 16px !important;
                            height: 16px !important;
                            width: 16px !important;
                        }
                    }
                }
            }
        }
    }
}

.upload-doc-dialog{   

    // &.ABB_CommonUX_Dialog__root{
    //     
    // }

    .ABB_CommonUX_Dialog__content{

        //Override defaults in ABB library        
        padding: 16px 32px 8px 32px;
        
        &>div{
            margin-bottom: 10px;
        }

        .upload-area{
            height: 130px;
        }

        .upload-doc-dialog__buttons{
            height: 40px;
            text-align: right;
            button {
                margin-left: 10px;
            }
        }
    }
}

.logo-wrapper {
	height: 10px; 
	display: inline-block; 
	width: 26px; 
	vertical-align: middle;
	margin-left: 1ch;
	padding: 2px;
	box-sizing: content-box;
	border-radius: 2px;
	border: 1px solid var(--muted-color-light);
}
