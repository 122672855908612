@import '../../shared.scss';

@mixin actuation-events-border {
	border: 1px solid $muted-color;
	border-radius: 0;
}

.actuation-events-selector-wrapper {
	background-color: $muted-background-dark !important;
	padding: 0 !important;
}
.actuation-events-bar {
	background-color: white;
	height: 27px;
	width: 100%;
}

.actuation-events-visible {
	background-color: $muted-color;
	@include actuation-events-border();
}

.actuation-events-bar-wrapper {
	flex: 1;
	position: relative;
	overflow-x: auto;
}

.actuation-events-time-label {
	@include generic-label();
	position: absolute;
	bottom: 0;
}

.actuation-events-toggle-label {
	text-transform: uppercase;
}

.actuation-events-label {
	@include generic-label();
	font-size: 0.9em;
	height: 24px;
	margin: 4px 0;
}

.actuation-events-date-picker {
	@include actuation-events-border();
	padding: 2px;
}

.actuation-events-toggle.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light.ABB_CommonUX_ToggleSwitch__switchedOn {
	
	> .ABB_CommonUX_ToggleSwitch__control, &:hover > .ABB_CommonUX_ToggleSwitch__control, &:active > .ABB_CommonUX_ToggleSwitch__control {
		background-color: $color-status-red;
		border-color: $color-status-red;
	}
}

.actuation-events-toggle.ABB_CommonUX_ToggleSwitch__root.ABB_CommonUX_ToggleSwitch__light {
	>.ABB_CommonUX_ToggleSwitch__control, &:hover > .ABB_CommonUX_ToggleSwitch__control, &:active > .ABB_CommonUX_ToggleSwitch__control {
		background-color: $color-status-green;
		border-color: $color-status-green;
	}
}
