@import "../../shared.scss";

.motor-unit-label {
	@include generic-label();
}

.motor-unit-radial-gauge {
	pointer-events: none;
	.ABB_CommonUX_Gauge__unit {
		font-size: 13px;
	}
}

.motor-unit-gauge-wrapper {
	height: 250px; 
	position: relative;
	margin-top: -30px;
	margin-bottom: 30px;
	.ABB_CommonUX_Tooltip__trigger {
		display: flex !important;
	}
}

.motor-unit-gauge-icon {
	position: absolute;
	left: 50%;
	transform: translate(-50%, 90px);
}
