@import "../../sass/Classes";

%info-row {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;

    span {
        width: 150px;
        color: #686868;
        font-family: ABBvoice;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
    }

    & > div {
        margin-left: 10px;
        color: #1f1f1f;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
    }
}

.settings-sensor {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #b8b8b8;
    
    .settings-sensor-type {
        height: 16px;
        color: #686868;
        font-size: 14px;
        line-height: 16px;
    }

    .settings-sensor-name {
        i {
            margin-right: 5px;
        }

        span {
            color: #262626;
            font-size: 18px;
            font-weight: 600;
            line-height: 27px;
        }
    }

    .settings-sensor-temperature,
    .settings-sensor-configuration,
    .settings-sensor-load {
        @extend %info-row;
    }

    .settings-sensor-load {
        .load-box {
            display: inline-block;

            margin-right: 5px;

            height: 16px;
            width: 16px;

            &.load-Low {
                background: #e8e8e8;
            }
            &.load-Mid {
                background: #adadad;
            }
            &.load-High {
                background: #5c5c5c;
            }
        }
    }

    .settings-sensor-buttons {
        button {
            width: 100%;
        }
    }
}

.icon {
    position: relative;
    &::after {
        display: block;
        content: '\25C9';
        position: absolute;
        right: .5rem;
        top: 50%;
        transform: translateY(-50%);
        line-height: 1;
    }
    &.icon-red::after {
        color: red;
    }
    &.icon-green::after {
        color: green;
    }
    &.icon-top::after {
        top: 0;
        transform: none;
    }
    &.icon-out::after {
        right: -1rem;
    }
}

.add-box {
	background-color: var(--muted-background-dark);
}
