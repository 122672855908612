@import "../../shared.scss";

.cb-status-item-wrapper {
	border: 1px solid black;
	width: 100%;
	padding: 15px;
	margin: 5px 0;
}

.alarm-text {
	color: $muted-color;
}

.alarm-icon {
	vertical-align: middle;
}

svg {
	.chevrons {
		opacity: 0;
		transition-property: opacity;
		transition-duration: 500ms;
		transition-timing-function: ease;
	}
	.center {
		opacity: 0;
		transition-property: transform opacity;
		transition-duration: 500ms;
		transition-timing-function: ease;
		transform-origin: 50% 100%;
    transform-box: fill-box;
	}
	.cross {
		opacity: 0;
		transition: opacity 500ms ease;
	}
}

svg[data-status="open:1;closed:0"] {
	.chevrons {
		transition-duration: 0ms;
	}
	.center {
		opacity: 1;
		transform: rotate(-20deg);
	}
}

svg[data-status="open:0;closed:0"], svg[data-status="open:1;closed:1"] {
	.chevrons {
		transition-duration: 0ms;
	}
	.cross {
		opacity: 1;
	}
}

svg[data-status="open:0;closed:1"] {
	.chevrons {
		opacity: 1;
		transition-delay: 500ms;
		transform: translate(0px);
		animation-name: move-chevrons;
		animation-duration: 500ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}
	.center {
		opacity: 1;
		transform: rotate(0deg);
	}
}

@keyframes move-chevrons {
	from {
		transform: translateY(0px);
	}
	to {
		transform: translateY(7.9994px);
	}
}

.title-wrapper {
	width: 40%;
}

.ready-wrapper {
	width: 30%;
	text-align: center;
}

.alarm-wrapper {
	width: 30%;
	text-align: right;
}
