@import "../../sass/Colors";
.section-card {
    height: 190px;
    &.section-border {
        border: 2px solid white !important;

        &.border-alert {
            border-color: $color-status-red !important;
        }
        &.border-warning {
            border-color: $color-status-orange !important;
        }
        &.border-ok {
            border-color: $color-status-green !important;
        }
    }

    .section-status {
        .status-icon {
            flex: 0 1 30%;
        }

        .status-description-container {
            flex: 0 1 70%;
            min-width: 160px;

            .staticDescription {
                color: $lastUpdate;
            }
        }
    }
}
