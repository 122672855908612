.file-uploader-container {
    width: 100%;
    height: 100%;
    margin: 0;

    .file-name {
      flex: 0 1 auto;
      margin-bottom: 10px;
    }

    .file-uploader {
        flex: 1 1 auto;
        background-color: #eeeeee;
        border: 1px dashed #979797;
        color: #3366FF;

        &.file-uploader--dragging {
            background-color: lightblue;

            &.file-uploader--rejecting{
                background-color: lightcoral;
            }  
        }      
    }
}
