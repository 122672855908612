.breadcrumbs-wrapper{
    width: 100%;
    height: 55px;
    padding-left: 1rem;

    &>span{
        margin: auto 0 auto 1em;

        a{
            color: inherit;
        }
    }

    .circle {
        border-radius: 50%;
        height: 5px;
        width: 5px;
        background-color: #C0C0C0;
        display: inline-block;
        margin: auto 0 auto 1em;
      }
      
}