@import "../../shared.scss";

.sync-status-label {
	@include generic-label();
}

.sync-status-description {
	font-weight: bold;
	text-transform: uppercase;
	line-height: 24px;
}

.sync-status-frequency {
	font-size: 2em;
	font-weight: bold;
	text-align: center;
	line-height: 32px;
}
