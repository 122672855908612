.Square {
    height: 10px;
    width: 10px;
    margin-top: auto;
    margin-bottom: auto;
}

.principal-text {
    font-size: 25px;
}

.separator {
    border-bottom: 1px solid lightgrey;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-right: 10px;
}

.ToggleButtonGroup-centered-noMargin {
    margin: 0px;
    padding: inherit;

    .ToggleButton-xs {
        min-width: 65px;
        width: 90px;
    }
}

.legendToggle-margin {
    margin-top: 5px;
    margin-bottom: 5px;

    .ToggleButton-xs-inside {
        margin: 0px;
        padding: inherit;
        //align-content: center;

        .ToggleButton-xs {
            min-width: 45px;
        }
    }
}

//------------------------------------------------------------------ 
.chartResp
    {
        width: 100%;
        margin: auto;
        min-height: 250px;        
    }
    @media  (min-height: 1401px)  {
        .chartResp{
        min-height: 800px;
        }
    }
    @media  (min-height: 1201px) and (max-height: 1440px) {
        .chartResp{
        min-height: 800px;
        }
    }
    @media  (min-height: 1080px) and (max-height: 1200px) {
        .chartResp{
        min-height: 600px;
        }
    }
    
    @media  (min-height: 768px) and (max-height: 1079px) {
        .chartResp{
        min-height: 400px;
        }
    }
/*
@media screen and (max-width:600) {
    .chartResp{
        display: none;
        min-height: 550px !important;     
    }   
}
@media  screen and(min-width:1198) {
    .chartResp{
        min-height: 650px !important;       
    } 
}
@media  screen and(min-width:2540) {
    .chartResp{
        min-height: 1000px !important; 
    }        
}*/




.chart-right-legend {
    .appChart {
        flex: 1 1 80%;
    }

    .legend-container {
        flex: 1 1 20%;
        min-width: 130px;
        justify-content: center;

        .legend-block{
            width: 120px;
            margin: 5px 0px 10px 10px;

            p{
                margin: 0;
            }
        }
    }
}
