.aspect-ratio-wrapper {
	position: relative;
	width: 100%;

	.aspect-ratio-content {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
