@import '../../shared.scss';

.startup-and-commissioning-device-card {
	background-color: $color-status-white;
	flex-grow: 1;	
	padding: 24px;
	display: flex;
	flex-flow: column;
}

.startup-and-commissioning-image-card {
	background-color: $color-status-white;
	padding: 8px;
	margin-right: 16px;
	flex-shrink: 0;
	align-self: flex-start;
}

.startup-and-commissioning-list-item {
	@include startup-and-commissioning-list-item();
	&.startup-and-commissioning-list-item-bold {
		@include startup-and-commissioning-list-item-bold();
	}
}
