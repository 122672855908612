@import '../../shared.scss';

.asset-identification-list-item {
	@include startup-and-commissioning-list-item();
	&.asset-identification-list-item-bold {
		@include startup-and-commissioning-list-item-bold();
	}
}

.network-info-subtitle, .sensors-subtitle {
	border-bottom: 2px solid $color-status-black;
}

.network-radio {
	justify-content: space-between;
	> label {
		margin: 0;
	}
}

.sensors-sensor-type, .sensors-list-description {
	color: $muted-color;
}

.sensors-load-icon {
	height: .75rem;
	width: .75rem;
	border-radius: 2px;
	display: inline-block;
	&.sensors-load-low {
		background-color: #E8E8E8;
	}
	&.sensors-load-mid {
		background-color: #ADADAD;
	}
	&.sensors-load-high {
		background-color: #5C5C5C;
	}
}

@mixin horizontal-dropdown {
	display: flex;
	align-items: center;
	>.ABB_CommonUX_Dropdown__label {
		margin-right: 8px;
		margin-bottom: 0;
	}
	>.ABB_CommonUX_Dropdown__comp {
		width: 100px;
	}
}
.sensors-horizontal-dropdown {
	@media (min-width: 1030px) {
		@include horizontal-dropdown();
	}
	@media (max-width: 830px) {
		@media (min-width: 680px) {
			@include horizontal-dropdown();
		}
	}
}

.sensors-auto-tune-wrapper {
	background-color: $muted-background;
	padding: 8px;
	> div {
		justify-content: center;
		@media (min-width: 831px) {
			justify-content: space-between;
		}
	}
}
