$shaadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

.alertPanel {
    margin: 2px 8px 0 0;

    width: 0;

    .ABB_CommonUX_TabControl__content{       

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: $shadow;
            box-shadow: $shadow;
            border-radius: 5px;
            background-color: #f5f5f5;
        }
    
        &::-webkit-scrollbar {
            height: 5px;
            width: 5px;
            background-color: #f5f5f5;
        }
    
        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            -webkit-box-shadow: $shadow;
            box-shadow: $shadow;
            background-color: rgb(121, 121, 121);
        }
    }
}

// From Small devices to Medium devices 
@media (min-width: 768px) and (max-width: 991.98px) {  
    .alertPanel{       

        width: 100% !important;
        height: 100%;
        background-color: #292626AA;        
        z-index: 2;  

        &>ul{
            background-color: lightgray;
            width: 60%;            
        }

        .ABB_CommonUX_TabControl__content{
            width: 60%;
        }
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { 
    .alertPanel{
        margin: 0px 8px 0 0;
        padding-top: 2px;

        position: fixed;        
        width: 100% !important;
        height: 100%;
        background-color: #292626AA;        
        z-index: 2;  
    }
}


// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .alertPanel{
        width: 400px;
        max-height: unset;
    }
}

