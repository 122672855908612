@import "../../sass/Colors";

.sensor-status-card{
    border-left: 7px solid !important;
    border-color:  #36f;

    min-height: 74px;
    
    &.embedded
    {
        background: #F0F0F0;
        height: 40px;
    }
    
    &.alarm{
        border-color: $color-status-red !important;
    }

    &.warning{
        border-color: $color-status-orange !important;
    }

    &.normal{
        border-color: $color-status-green !important;
    }
}
