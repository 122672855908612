
.SensorOverview {
    background: transparent;
    margin: 5px 0;
    // padding: 0 5px;    
}

.SensorOverviewSurrounded
{
    background-color: white;
    width: 100%;
    height: 100%;    
    border: 1px solid black;
    padding: 10px 15px;
}

.SensorOverviewAdvice
{ 
    background: #F0F0F0;;   
}

.advice-normal{
    font-size: 12px;
}

.SensorOverviewSquareState{
    height: 16px;
    width: 16px; 

}

.SensorOverviewContentCentered{
  align-items: center;
  min-height: 30px;
  padding: 0 2px;
}

.SensorOverviewContentRightCentered{
    align-items: flex-end;
  }

.SensorOverviewBackgroundLow{
    background: #D7F7F3;   
}
.SensorOverviewBackgroundMid{
    background: #7FD6C7;   
}
.SensorOverviewBackgroundHigh{
    background: #3CA191;   
}

.disabled-sensor-overview
{
    min-height: 50px;

    &>span{
        display: -ms-flexbox !important;
        display: flex !important;

        &>i
        {
            margin: auto 0;
        }
    }
}
