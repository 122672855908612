.system-info {
    .AdviceTBolder {
        font-weight: bolder;
    }

    .lineHeightStrong {
        line-height: 2.5;
    }

    .principalText {
        font-size: 20px;
    }
}
