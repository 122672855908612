@import "../../shared.scss";

.card-hidden-content {
	.card-content {
		overflow: hidden;
		margin: 0 !important;
	}
}

.cumulated-workload-label {
	@include generic-label();
}

.cumulated-workload-alert {
	@include generic-alert();
}

.slider-wrapper {
	display: flex;
	height: 100%;
	width: calc(200% + 60px);
	> div {
		width: 50%;
	}
}

.page-right {
	animation-name: slide-right;
	animation-duration: 500ms;
	animation-fill-mode: forwards;
}

.page-left {
	animation-name: slide-left;
	animation-duration: 500ms;
	animation-fill-mode: forwards;
}


@keyframes slide-left {
	0% {
		transform: translateX(-50%);
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
	100% {
		transform: translateX(0%);
		opacity: 1;
	}
}

@keyframes slide-right {
	0% {
		transform: translateX(0%);
		opacity: 1;
	}
	50% {
		opacity: 0.4;
	}
	100% {
		transform: translateX(-50%);
		opacity: 1;
	}
}
