$muted-color: gray;
$muted-background: #f0f0f0;
$muted-background-dark: #ebebeb;
$color-status-yellow: #FFD800;
$color-status-orange: #FF7300;
$color-status-red: #F03040;
$color-status-magenta: #CB2BD5;
$color-status-blue: #0052FF;
$color-status-green: #0CA919;
$muted-color-dark: #1f1f1f;
$color-status-black: black;
$muted-color-light: #dbdbdb;
$color-status-white: white;

:root {
	--color-status-yellow: #{$color-status-yellow};
	--color-status-orange: #{$color-status-orange};
	--color-status-red: #{$color-status-red};
	--color-status-magenta: #{$color-status-magenta};
	--color-status-blue: #{$color-status-blue};
	--color-status-green: #{$color-status-green};
	--muted-background: #{$muted-background};
	--muted-background-dark: #{$muted-background-dark};
	--muted-color: #{$muted-color};
	--muted-color-light: #{$muted-color-light};
	--muted-color-dark: #{$muted-color-dark};
	--color-status-black: #{$color-status-black};
	--color-status-white: #{$color-status-white};
}

@mixin generic-alert {
	background-color: $muted-background;
	width: 100%;
	padding: 5px;
}

@mixin generic-label {
	color: $muted-color;
	font-size: .85em;
}

@mixin startup-and-commissioning-list-item {
	display: flex;
	flex-flow: column;
	margin: 8px 0;
	> span:first-child {
		color: $muted-color;
		font-size: 0.8rem;
	}
}

@mixin startup-and-commissioning-list-item-bold {
	> span:nth-child(2) {
		font-weight: bold;
		font-size: 1rem;
	}
}
