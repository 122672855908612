@import '../../shared.scss';

.product-list-item {
	@include startup-and-commissioning-list-item();
	&.product-list-item-bold {
		@include startup-and-commissioning-list-item-bold();
	}
}

.firmware-list-item {
	@include startup-and-commissioning-list-item();
	&.product-list-item-bold {
		@include startup-and-commissioning-list-item-bold();
	}
}

.documents-list-item {
	@include startup-and-commissioning-list-item();
	&.product-list-item-bold {
		@include startup-and-commissioning-list-item-bold();
	}
}

.firmware-file-uploader-wrapper, .documents-file-uploader-wrapper {
	max-width: 300px;
	> .file-uploader-container {
		> .file-uploader {
			padding: 24px;
			width: auto;
		}
	}
}

.additional-sensors-sensor-type {
	color: $muted-color;
}
