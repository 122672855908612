.ExternalTimeScaleContainerMargins
{
    padding-Right: 5px; 
    align-Items: center; 
    flex-Wrap: wrap;
}

.ExternalTimeScaleMargins{
        margin-Top: 5px; 
        margin-Bottom: 5px; 
        margin-Right:10px;
}

.DatePickerContainer{
    margin-Top: 5px;
    margin-Bottom: 5px;
}
.noMargin{
    margin:0;
}
.noPadding{
    padding:0;
}

.label-without-margin {
	label {
		margin-bottom: 0 !important;
	}
}
