.bubble-square {
    height: 10px;
    width: 10px;
    margin-top: auto;
    margin-bottom: auto;
}

.bubble-circle{
    height: 10px;
    width: 10px;
    border-radius:50px;
    margin-top: auto;
    margin-bottom: auto;
    opacity: 0.6;
}

.principal-text {
    font-size: 25px;
}

.separator {
    border-bottom: 1px solid lightgrey;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-right: 10px;
}

.ToggleButtonGroup-centered-noMargin {
    margin: 0px;
    padding: inherit;

    .ToggleButton-xs {
        min-width: 65px;
        width: 90px;
    }
}

.legendToggle-margin {
    margin-top: 5px;
    margin-bottom: 5px;

    .ToggleButton-xs-inside {
        margin: 0px;
        padding: inherit;
        //align-content: center;

        .ToggleButton-xs {
            min-width: 45px;
        }
    }
}

//------------------------------------------------------------------

.chart-right-legend {
    .appChart {
        flex: 1 1 80%;
    }

    .legend-container {
        flex: 1 1 20%;
        min-width: 130px;
        justify-content: center;

        .legend-block{
            width: 120px;

            p{
                margin: 0;
            }
            margin: 5px 0px 10px 10px;
        }
    }
}
