@import "../../sass/Classes";

.settings-container{
    .firmware-update{
        border-top: 1px solid #979797;

        &>span{
            display: block;
            margin: 20px 0;
        }

        .upload-area{
            // background-color: #EEEEEE;
            // border: 1px dashed #979797;
            height: 66px;
        }
    }

    .database-content{
        p{
            margin-top: 15px;
            margin-bottom: 5px;
        }
    }

    .all-buttons {        
        button{
            @extend %fixed-small-button;

            width: 130px;
        }
    }

    .channel-box{
        background: #EBEBEB;
        min-height: 100px;
    }

    .training-row{
        button{
            @extend %fixed-small-button;

            width: 100px;
        }

        .training-feedback{
            border: 2px solid transparent;
            padding: 1.5em;
    
            &.success{
                border-color: green;
            }
    
            &.failed
            {
                border-color: red;
            }
        }
    }    
}
