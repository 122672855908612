$toggled-background: #dbdbdb;

.navbar {

    padding: 0 5%;

    .navbar-toggler {
        border: none;

        .navbar-toggler-icon {
            background-image: url("./img/hamburgericon.jpg");
        }
    }

    .navbar-collapse {
        transition: none;
				background: white;
    }

    .alert-bell {
        &.toggled {
            background-color: $toggled-background;

            &::after {
                content: "";
                position: absolute;
                z-index: 1000;
                left: 30%;
                top: 100%;
                width: 0;
                height: 0;
                border-left: 12px solid transparent;
                border-right: 12px solid transparent;
                border-top: 12px solid $toggled-background;
                clear: both;
            }

            &:hover {
                &::after {
                    border-top: 12px solid #ebebeb;
                }
            }
        }
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .navbar {
        border-bottom: 3px solid slategray;

        .navbar-collapse {
            z-index: 10;
            position: fixed;
            top: 74.5px;
            left: 0;
            width: 100%;
            height: 100vh;

            .menu-container {
                .ABB_CommonUX_AppTopNavi__item {
                    & > .ABB_CommonUX_AppTopNavi__header {
                        height: 55px;

                        & > div {
                            padding-top: 14px;
                        }
                    }

                    &.ABB_CommonUX_AppTopNavi__active {
                        background-color: $toggled-background;

                        & > .ABB_CommonUX_AppTopNavi__header {
                            &::after {
                                height: 0;
                            }

                            border-left: 3px solid #36f;
                        }
                    }
                }
            }
        }
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    .navbar {
        .navbar-collapse {
            background-color: white;

            .menu-container {
                width: 100%;
            }
        }
    }
}

// From Small devices to Medium devices (tablets, less than 992px)
@media (min-width: 768px) and (max-width: 991.98px) {
    .navbar {
        border-bottom: 3px slategray solid;
        z-index: 10;

        .navbar-collapse {
            background-color: #292626aa;

            .menu-container {
                width: 55%;
                height: 100vh;
                opacity: unset;
                background-color: white;
            }
        }
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .navbar {
        .navbar-collapse {
            .menu-container {
                width: inherit;
                height: inherit;
                background-color: inherit;
                display: inherit;
            }
        }
    }
}

// Smaller than Large devices
@media (max-width: 1199.98px) {
    .navbar {
        padding: 0;
    }
}


.justify-child-content-end > * {
    justify-content: flex-end;
}
