%box{
    span {

        // height: 16px;
        color: #686868;
        font-size: 14px;
        line-height: 16px;
    }
}

.asset-information {

    .title-container
    {
        h2{
            margin: 20px 0 0 0;
            line-height: unset;            
        }

        .sub-title{
            margin: 0;
            height: 15px;
            color: #262626;
            font-size: 12px;
            font-weight: 300;
            line-height: 15px;
        }
    }

    .info-container{
        padding: 0 26px;

        &.bordered{
            border-bottom: 1px solid #BABABA;
        }

        &> div{
            // width: 50%;
            margin-top: 5px;
            margin-bottom: 20px;;
        }
    }

    .number-box {
        @extend %box;

        .number {
            box-sizing: border-box;
            height: 32px;
            width: 170px;
            border: 1px solid #bababa;
            background-color: #ebebeb;

            text-align: center;
            line-height: 30px;
        }
    }

    .info-box{
        @extend %box;

        .info{
            height: 18px;
            color: #1F1F1F;
            font-size: 14px;
            line-height: 18px;
          }
    }
}
