@import "../../sass/Colors";

.connection-status-card {
    min-height: 120px;

    .connection-status-content {
        & > div {
            padding: 5px;
            background-color: white;
            height: 48%;
            width: 100%;
        }

        .notification-container{
            i{
                margin: auto 0;
            }

            span{
                margin: auto 5px;
            }
        }

        .status-container {
            border-left: 7px solid !important;
            border-color: #36f;

            &.alarm {
                border-color: $color-status-red !important;
            }

            &.warning {
                border-color: $color-status-orange !important;
            }

            &.normal {
                border-color: $color-status-green !important;
            }
        }
    }
}
