@import '../../shared.scss';

.modal-with-steps-wrapper {
	> .ABB_CommonUX_Dialog__root.ABB_CommonUX_Dialog__light {
		border-top: 0;
		margin-top: 0;
		height: 100%;
	}
	width: 85%;
	height: 90%;
	@media (max-width: 900px) {
		width: 95%;
	}
}

.modal-with-steps-content {
	padding: 0 !important;
	height: 100%;
	background-color: $muted-background-dark;
}

.step-wrapper {
	display: flex;
	flex-flow: row;
	background-color: $muted-background-dark;
}


.step-content-wrapper {
	flex-grow: 1;
	overflow-y: auto;
	.step-content-navigator {
		display: flex;
		flex-flow: row;
		justify-content: flex-end;
		width: 100%;
	}
	.step-content:not(.active) {
		display: none;
	}
	.step-content {
		background-color: $color-status-white;
		overflow-x: auto;
	}
}

.step-selector-wrapper {
	display: flex;
	flex-flow: column;
	overflow-y: auto;
	flex-shrink: 0;
	.step-selector-button {
		outline: none;
		border: 4px solid transparent;
		border-top: 0;
		border-bottom: 0;
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;
		font-size: 0.75rem;
		padding: 20px;
		color: #1f1f1f;
		&:disabled {
			color: #1f1f1f;
		}
		&.active {
			border-left: 4px solid $color-status-blue;
			background-color: #dbdbdb;
		}
	}
}
