

.Surrounded
{
    background-color: white;
    width: 100%;
    height: 100%;    
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin: 5px 0;
    border: 2px solid black;
    padding: 10px;
}

.AdviceTBolder
{
    font-weight: bolder;    
}

.ContentCentered{
  align-items: center;
}

.ContentRightCentered{
    align-items: flex-end;
  }

.CenteredText{
    text-align: center;
}

.LeftCenteredText{   
    text-align: left;
}

.RightCenteredText{   
    text-align: right;
}

.BackgroundLow{
    background: #E8E8E8;   
}
.BackgroundMid{
    background: #ADADAD;   
}
.BackgroundHigh{
    background: #5C5C5C;   
}

.LargePadding {
    background: transparent;
    margin: 5px 0;
    padding: 0 15px;
}