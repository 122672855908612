@import '../../shared.scss';

button.add-user-button:not(:disabled) > div > span.ABB_CommonUX_Button__text.ABB_CommonUX_Button__hasText,
button.add-user-button:not(:disabled) > div > span.ABB_CommonUX_Button__icon > .ABB_CommonUX_Icon__root {
	color: $color-status-white;
}

.user-list-card .card-title {
	align-items: baseline;
}
