@import '../shared.scss';

$shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

/* Application styling begins: */

.fullHeight {
    height: 100%;
}

.tabHeaderAnimation {
    & > ul {
        transition: padding 0.3s;
    }
}

.paddings {
    padding: 16px;
}

.notification-container {
    top: 100px;
    right: 30px;
}

.appChart {
    width: 100%;
    //margin: auto;
    min-height: 250px;

&.chartResp
    {

        min-height: 250px;        
    }
}

@media  (min-height: 1401px)  {
    .chartResp{
    min-height: 1000px;
    }
}
@media  (min-height: 1201px) and (max-height: 1440px) {
    .chartResp{
    min-height: 800px;
    }
}
@media  (min-height: 1080px) and (max-height: 1200px) {
    .chartResp{
    min-height: 600px;
    }
}

@media  (min-height: 768px) and (max-height: 1079px) {
    .chartResp{
    min-height: 400px;
    }   
}
@media  (max-height: 767px) {
    .chartResp{
    min-height: 250px;
    }
}

.ABB_CommonUX_AppContent__root,
.ABB_CommonUX_AppMainContent__root {
    //hide scrollbar but keep scroll
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

.ABB_CommonUX_TabControl__content {
    scrollbar-color: rgb(121, 121, 121) #f5f5f5;
    scrollbar-width: thin;

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: $shadow;
        box-shadow: $shadow;
        border-radius: 5px;
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
        width: 5px;
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: $shadow;
        box-shadow: $shadow;
        background-color: rgb(121, 121, 121);
    }
}

.ABB_CommonUX_AppContent__root,
.ABB_CommonUX_AbbBar__root {
    padding: 0 5%;

    // Smaller than Large devices
    @media (max-width: 1199.98px) {
        padding: 0;
    }
}

.ABB_CommonUX_Gauge__label {
    font-size: 16px;
    font-weight: 900;
}

.ABB_CommonUX_Tooltip__trigger {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
    margin: auto 0;
}

#appLoginScreen.ABB_CommonUX_LoginScreen__root {
    max-width: 720px;
    width: unset;
    margin: auto;
}


.ABB_CommonUX_MessageDialog__messageDialogDimensions, .ABB_CommonUX_Dialog__root{
    min-width: 370px;
    overflow-y: auto;
}

.ABB_CommonUX_Dialog__standardButtonsOnBottom{
    .ABB_CommonUX_Button__smallSize {
        min-width: unset;
    }
}

@supports (-ms-ime-align:auto) {
    .ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__radial>svg {
        //needed for Edge compatibility, otherwise label will be partially hidden
        padding-top: 12px;
    }
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__radial>svg {
    //needed for Edge compatibility
    // padding-top: 12px;

    &> g {

        &> text {
            //needed for compatibility with Firefox which not understand alignment-baseline
            &.ABB_CommonUX_Gauge__min, &.ABB_CommonUX_Gauge__max, &.ABB_CommonUX_Gauge__label{
                dominant-baseline: text-before-edge;
            }            
        }            
    }
}

.ABB_CommonUX_NotificationContainer__root {
	z-index: 1001; // modal z-index is 1000, notifications must be over modal
}

@media (max-width: 767.98px) {
    .ABB_CommonUX_NotificationContainer__root {
        max-width: 360px;

        top: 20px;
        right: 10px;
        .ABB_CommonUX_Notification__root{
            min-width: 350px;
            margin: 0;
        }
    }
}

@media (max-width: 900px) {
    .ReactModal__Content {
        display: flex;        

        .ABB_CommonUX_Dialog__root {
            margin: auto;
            height: auto;
            // width: 80%;
        }
    }
}

@import "../sass/Imports";

.card-wrapper > .card.widget-uninstalled {
	background-color: #f7f7f7;
	.card-content-wrapper {
		cursor: not-allowed;
	}
	.card-content {
		opacity: 0.6;
		pointer-events: none;
	}
}

.disabled-header {
	opacity: 0.5;
	pointer-events: none;
}
