.tooltip {
    .in{
        opacity: 1 !important;
    }
  
    // .tooltip-arrow {
  
    //   &::before {
    //     position: absolute;
    //     content: "sucaaaa";
    //     border-color: red;
    //     border-style: solid;
    //   }
    // }
  }
  
  .bs-tooltip-top,.bs-tooltip-auto[x-placement^="top"]  {
  
    .tooltip-arrow {
        background: white !important;
  
      &::before {
        border-top-color: white !important;
      }
    }
  }
  
  .bs-tooltip-right,.bs-tooltip-auto[x-placement^="right"]  {
  
    .tooltip-arrow {

      &::before {
        border-right-color: white !important;
      }
    }
  }
  
  .bs-tooltip-bottom,.bs-tooltip-auto[x-placement^="bottom"]  {
  
    .tooltip-arrow {
      &::before {
        border-bottom-color:  white !important;
      }
    }
  }
  
  .bs-tooltip-left,.bs-tooltip-auto[x-placement^="left"]  {
  
    .tooltip-arrow {
  
      &::before {
        border-left-color:  white !important;
      }
    }
  }
  
  // Wrapper for the tooltip content
  .tooltip-inner {
    color: black;
    text-align: center;
    background-color: white;
    border-radius: 0em;
    border: 1px solid lightgray;        
  }