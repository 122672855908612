.family-card {    

    height: 190px;

    .family-status {
        margin: 0 !important;

        .status-container {
            height: 100%;
        }

        .more-container {
            height: 35px;
        }

        .alert-message{
            background: #F0F0F0;
            height: 35px;
            padding: 0 5px;

            a{
                text-decoration: none;
            }
        }
    }
}
