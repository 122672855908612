.alerts-widget {
    .alert-value-box {
        .alert-value-content {
            width: 100%;
            text-align: center;

            .label {
                font-weight: 800;
                font-size: 16px;
            }

            .value {
                font-size: 32px;
                font-weight: 800;
            }            
        }
    }

    .alert-value-icon {
        width: 48px;
    }
}
