@import "../../shared.scss";

.input-status-item-wrapper {
	border: 1px solid black;
	width: 100%;
	padding: 15px;
	margin: 5px 0;
}

.input-status-item-label {
	text-transform: uppercase;
	color: $muted-color;
	font-size: 0.7rem;
}

.circle-icon {
	height: 1em;
}
