@import "../../sass/Colors";

.card-wrapper{

    background: transparent;
    margin: 5px 0;
    padding: 0 5px;

    .card{

        &.browsable {
            border: 1px solid white !important;
    
            &:hover {
                border: 1px solid #3366ff !important;
            }
        }

        background-color: white;
        width: 100%;
        height: 100%;
        margin: auto;
        padding: 1rem;

        //Needed to override bootstrap defaults
        border: none;
        border-radius: 0;

        &.disabledWidget {
            background: #cdd0d3d7;
        }

        .card-title {
    
            border-bottom: 2px solid black;
            // margin: 1em;
        
            h4{
                font-size: 16px;
                font-weight: 900;
            }
        
            span{
                color: $lastUpdate;
            }
        }

        .card-content-wrapper{
            height: 100%;

            .card-content{
                margin: auto 0;
                width: 100%;
            }
        }
    }

}