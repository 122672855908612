%square {
    width: 8px;
    height: 8px;
    border-radius: 1px;
}

.phase-sensor-card {   

    a {
        color: unset;
        text-decoration: none;
    }

    .phase-sensor {
        .arms {
            .arm {
								.square {
										@extend %square;
								}
            }
        }

        .load {
            .square {
                @extend %square;
                margin: auto 0.5em;

                &.low {
                    background-color: #d7f7f3;
                }

                &.mid {
                    background-color: #7fd6c7;
                }

                &.high {
                    background-color: #3ca191;
                }
            }
        }
    }
}
.verticalMarginAuto{
    margin-top: auto;
    margin-bottom:auto;
}
