@import "../../sass/Colors";

.mechanical-sensor{
    border-left: 7px solid !important;
    border-color:  #36f;

    min-height: 74px;
    
    &.alarm{
        border-color: $color-status-red !important;
    }

    &.warning{
        border-color: $color-status-orange !important;
    }

    &.normal{
        border-color: $color-status-green !important;
    }

    .mechanical-sensor-content > div{       

        min-height: 40px;

        span, i {
            margin: auto 0;
        }
    }


}
